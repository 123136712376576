<template>
  <div class="financeRecord" :style="financeRecordStyle">
    <div class="descriptions_title">财务审批记录</div>
    <el-steps direction="vertical">
      <el-step v-for="item in stepsData" status="process" :class="{'isSuccess':item.eventType === 2}">
        <template slot="icon" >
          <i class="step" slot="icon"></i>
        </template>
        <template slot="title" >
          <div class="flex">
            <span class="time_box">{{formatDate(item.createTime)}} </span>
            <div
              v-if="item.eventType === 2"
              class="btn_box success_btn"
            >通过</div>
            <div
              v-if="item.eventType === 3"
              class="btn_box reject_btn"
            >驳回</div>
            <div
              v-if="item.eventType === 8"
              class="btn_box tishen_btn"
            >提审</div>
            <div
              v-if="item.eventType === 9"
              class="btn_box create_btn"
            >RAF创建</div>

<!--            <el-tag-->
<!--              v-if="item.eventType === 2"-->
<!--              class="step_title"-->
<!--              key="驳回"-->
<!--              type="success"-->
<!--              effect="dark">-->
<!--              通过-->
<!--            </el-tag>-->
<!--            <el-tag-->
<!--              v-if="item.eventType === 3"-->
<!--              class="step_title"-->
<!--              key="驳回"-->
<!--              type="danger"-->
<!--              effect="dark">-->
<!--              驳回-->
<!--            </el-tag>-->
          </div>
        </template>
        <template slot="description" >
          <div class="description_box" v-if="item.eventType === 2">
            <div>通过原因：</div>
            <span style="max-width: 800px">
              {{item.approveOperationalOpinions}}
            </span>
          </div>
          <div class="description_box" v-if="item.eventType === 3">
            <div>驳回原因：</div>
            <span style="max-width: 800px">
              {{item.operationalOpinions}}
            </span>
          </div>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import { getFinanceRecord } from "@/api/finance";

export default {
  name: "financeRecord",
  props:{hcId:''},
  data(){
    return{
      stepsData:[],
    }
  },
  computed:{
    financeRecordStyle(){
      return 'height: ' + this.stepsData.length * 100 + 'px';
    }
  },
  created() {
    this.getFinanceRecord(this.hcId)
  },
  methods:{
    formatDate(date) {
      if (date) {
        return date.substring(0, 10)
      } else {
        return ''
      }
    },
    getFinanceRecord(hcId){
      getFinanceRecord({hcId:hcId}).then(({code,data})=>{
        if(code === 200){
          console.log(data);
          this.stepsData = data
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.financeRecord{
  padding-bottom: 60px;
  margin-top: 30px;
  .time_box{
    color: #828282;
    font-size: 12px;
  }
  .btn_box{
    // width: 48px;
    min-width: 48px;
    padding: 0 5px;
    box-sizing: border-box;
    height: 20px;
    border-radius: 2px;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    position: relative;
    top: 5px;
    right: 140px;
  }
  .reject_btn{
    background: #DE3934;
  }
  .success_btn{
    background: #2490E3;
  }
  .tishen_btn{
    background: #6EBAAF;
  }
  .create_btn{
    background: #6EBAAF;
  }
  .el-steps--vertical{
    margin-left: 60px;
    margin-top: 20px;
  }
  .descriptions_title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
  }
  .description_box{
    background-color: #F4F4F4;
    max-width: 800px;
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  ::v-deep .el-step__description{
    padding-right: 0;
  }
  ::v-deep .el-step__head{
    width: 6px;
    .el-step__title{
      line-height: 0;
    }
    .el-step__line{
      width: 1px;
      top: 10px;
      bottom: -10px;
      left: 2px;
    }
    .el-step__icon.is-text{
      border: none;
    }
    .el-step__icon{
      background-color: #BA9765;
      width: 5px;
      height: 5px;
    }
  }
  .isSuccess{
    flex-basis: 30% !important;
  }

}
</style>
