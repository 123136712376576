<template>
  <div class="HC">
    <el-row>
      <el-radio-group @change="search" v-model="searchStatus">
        <el-radio label="ALL">ALL</el-radio>
        <el-radio label="To Be Approved">待审批</el-radio>
        <el-radio label="APPROVED">已通过</el-radio>
        <el-radio label="REJECTED">已退回</el-radio>
      </el-radio-group>
      <div style="margin-top: 16px; margin-bottom: 12px" class="flexBet">
        <div>
          <el-button v-show="searchStatus !== 'APPROVED'" role="pass" v-permission @click="showPass()">通过</el-button>
          <el-button v-show="searchStatus !== 'APPROVED'" role="rejected" v-permission @click="showReject()"
          >驳回</el-button>
        </div>
        <div class="total_box">
          <div class="total_text">
            <span>Count：</span>{{count}}
          </div>
          <div class="total_text">
            <span>Total Cost(LC)：</span>{{totalCost}}
          </div>
        </div>
      </div>
    </el-row>
    <PocTable
      ref="tTableRef"
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      :page-size.sync="pageConfig.pageSize"
      stripe
      :max-height="1000000000"
      @selection-change="handleSelectionChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @row-click="rowClick"
      @select-all="selectAll"
      @sort-change="sortChange"
      highlight-current-row
    >
      <el-table-column type="selection" width="50" fixed="left" label-class-name="column_selection" />
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :align="item.align"
        :sortable="Type === 'HC' ? item.sortable : false"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        header-align="center"
      >
        <template v-slot="scope">
          <span v-if="item.key === 'businessCaseToJustifyTheRecruitment'">
            <el-tooltip
              v-if="scope.row[item.key] "
              popper-class="workorder-reason-popper"
              effect="dark"
              :content="scope.row[item.key]"
              placement="top">
                 <span>{{ scope.row[item.key] | ellipsis}}</span>
                </el-tooltip>
            <span v-else>{{scope.row[item.key]}}</span>
          </span>
          <span v-else-if="item.key === 'firstGroupCfoSendApproveTime' || item.key === 'groupCfoApproveTime' || item.key === 'groupCfoRejectTime'">
            {{formatDate(scope.row[item.key])}}
          </span>
          <span v-else :class="{'textHover':item.key === 'jobTitle'}" @click="cat(scope.row,item.key === 'jobTitle')">
            <div class="jobTitle_box">
              <span>
                {{ ['annualTotalCost','ifBackfillDepartingEmployeeAnnualTotalCost'].includes(item.key) ? currency(scope.row[item.key]): scope.row[item.key] }}
              </span>
              <span>
                 <img v-if="item.key === 'jobTitle' && scope.row['messageType']=== '2' " class="messageImg" src="@/assets/img/redMessage.png" alt="">
                <img v-if="item.key === 'jobTitle' && scope.row['messageType']=== '1' " class="messageImg" src="@/assets/img/message.png" alt="">
              </span>
            </div>
          </span>

        </template>
      </el-table-column>

      <el-table-column
        label="Operation"
        width="90"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <svg-icon
            icon="tableChat"
            class="tablePointSvgClass"
            title="查看"
            @click.native="cat(scope.row,true)"
          />
        </template>
      </el-table-column>
    </PocTable>
    <financialQuotaDrawer ref="financialQuotaDrawerRef" :param="financialQuotaParam"
                          @pass="showPass" @showReject="showReject"  @next="next" @queryList="queryApi"
    />
    <financeReject ref="financeRejectRef" @reject="reject" />
    <financePass ref="financePassRef" @pass="pass" @closePass="closePass" />
  </div>
</template>

<script>
import { TableColumnsObj } from '@/views/financialApproval/TableColumn'
import pocSlider from '@/components/poc-slider/poc-slider'
import { approve, financeList, reject, isReject } from '@/api/finance'

import { check, typeRange } from '@/utils/util'
import { getItem, setItem } from '@/utils/storage'
import currency from "currency.js";
import financialQuotaDrawer from "@/views/financialApproval/financialQuotaDrawer";
import financeReject from '@/views/financialApproval/financeReject.vue'
import financePass from "@/views/financialApproval/financePass.vue";
export default {
  name: 'HC',
  mixins: [$PCommon.TableMixin],
  props: {
    Type: {
      require: true
    },
    formLine: {
      require: true
    }
  },
  components: {
    pocSlider,
    financialQuotaDrawer,
    financeReject,
    financePass,
  },
  computed:{
    count(){
      return this.selectVal.length
    },
    totalCost(){
      if(this.selectVal.length > 0){
        let cost = Number(0)
        this.selectVal.forEach(a=>{
          cost+= Number(a.annualTotalCost)
        })
        return this.currency(cost)
      }
      return '0.00'
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 600) {
        return value.slice(0, 600) + "...";
      }
      return value;
    }
  },
  watch: {
    Type: {
      handler(val) {
        this.searchType = val
        this.queryApi()
      },
      deep: true,
      immediate: true
    },
    formLine: {
      handler(val) {
        if (val) {
          this.formInline = val
        }
      },
      deep: true,
      immediate: true
    },
    searchStatus: {
      handler(val) {
        console.log('radio--change', val)
        this.$emit('radio', val)
      }
    }
  },
  data() {
    return {
      loading: false,
      columnConfig: TableColumnsObj.ToBeApproved,
      tableData: [],
      tableDataNext: [],
      searchStatus: 'To Be Approved',
      searchType: '',
      formInline: {},
      selectVal: [],
      maxHeight: '',
      formStatus: '',
      rejectId: '',
      pageNum: 1,
      showDrawer:true,
      isShowTooltip: false,
      pageConfig:{pageSize: 20},
      financialQuotaParam:{
        position:'',
        title:'',
        status:'',
        partTitle:'',
      },
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 445
  },
  mounted() {
    // let body = document.querySelector('.el-card__body')
    // let header = document.querySelector('.el-card__header')
    // let headerHeight = header.clientHeight
    // body.style.height = window.innerHeight - headerHeight - 60 - 67 + 'px'
    // this.queryApi()
  },
  methods: {
    formatDate(date) {
      if (date) {
        return date.substring(0, 10)
      } else {
        return ''
      }
    },
    currency(value){
      return currency(value,{symbol:''}).format()
    },
    findTablePoint() {
      let finPoint = getItem('finPoint')
      if (finPoint) {
        if (finPoint.id) {
          let tableData = this.tableData.findIndex(
            (item) => item.id === finPoint.id
          )
          if (tableData) {
            this.$refs.tTableRef.setCurrentRow(this.tableData[tableData], true)
          }
        }
      }
    },
    cat(val,flag) {
      console.log(val);
      if(!flag){
        return
      }
      const data = {
        position: this.searchType,
        id: val.id
      }
      setItem('finPoint', data)
      this.financialQuotaParam.id = val.id
      this.financialQuotaParam.status = 'chat'
      this.financialQuotaParam.target = 'financial'
      this.financialQuotaParam.searchStatus = this.searchStatus
      this.financialQuotaParam.hcId = val.number
      this.financialQuotaParam.ctoApproveStatus = val.ctoApproveStatus
      this.financialQuotaParam.groupCFOApprovalStatus = val.groupCFOApprovalStatus
      if (this.searchType === 'HC') {
        this.financialQuotaParam.position = 'hc'
        this.financialQuotaParam.title = 'HC 信息'
      } else if (this.searchType === 'Offer') {
        this.financialQuotaParam.position = 'offer'
        this.financialQuotaParam.title = 'Offer 信息'
      }
      this.$refs.financialQuotaDrawerRef.drawer = true
    },
    onConfirm(val) {},
    showReject(){
      if(this.selectVal.length === 0 && !this.$refs.financialQuotaDrawerRef.detailId){
        this.$message.error('请选择要驳回的数据')
        return
      }
      if(this.selectVal.length > 0) {
        const data = {
          ids: this.selectVal.map((item) => item.id).toString(','),
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        isReject(data).then(res => {
          if (res && res.code == 200) {
            this.$refs.financeRejectRef.dialogVisible = true
          }
        })
        return
      }
      this.$refs.financeRejectRef.dialogVisible = true
    },
    reject(val) {
      let detailId = this.$refs.financialQuotaDrawerRef.detailId
      if(detailId){
        const data = {
          ids: detailId,
          reason: val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        reject(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('驳回成功')
            this.$refs.financeRejectRef.loading = false
            this.$refs.financialQuotaDrawerRef.rejected = true
            this.$refs.financialQuotaDrawerRef.reloadDate()
            this.queryApi()
          }
        })
        return
      }
      if (this.selectVal.length > 0) {
        const data = {
          ids: this.selectVal.map((item) => item.id).toString(','),
          reason: val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        reject(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('驳回成功')
            this.$refs.financeRejectRef.loading = false
            this.queryApi()
          }
        })
      }
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    showPass(val){
      let ids = ''
      if(val){
        ids = val
        this.$refs.financePassRef.show(ids)
      }else {
        if (check(this.selectVal) === typeRange.array && this.isSelect()) {
          ids = this.selectVal.map((item) => item.id).toString(',')
          this.$refs.financePassRef.show(ids)
        }
      }
    },
    closePass(){
      if(this.$refs.financialQuotaDrawerRef){
        this.$refs.financialQuotaDrawerRef.loading = false
      }
    },
    pass(val) { //val : 详情页 返回id
      if(val){
        const data = {
          ids: val.ids,
          groupCFOApprovalReason: val.groupCFOApprovalReason,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        approve(data).then((res) => {
          this.$refs.financePassRef.loading = false
          if(this.$refs.financialQuotaDrawerRef){
            this.$refs.financialQuotaDrawerRef.loading = false
            this.$refs.financialQuotaDrawerRef.reloadDate()
          }
          if (res && res.code === 200) {
            this.$message.success('通过成功')
            this.queryApi()
          }
        })
      }

    },
    async queryApi() {
      this.loading = true
      this.pageNum = this.pageConfig.pageNum

      let data = {
        ...this.formInline,
        searchType: this.searchType,
        searchStatus: this.searchStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum
      }
      if (this.searchStatus != 'ALL' && this.formInline.date && this.formInline.date.length > 0) {
        data.auditBeginTime = this.formInline.date[0] || '';
        data.auditEndTime = this.formInline.date[1] || '';
      }
      financeList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.tableDataNext = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
          if(this.$refs.financialQuotaDrawerRef){
            this.$refs.financialQuotaDrawerRef.total = this.pageConfig.total
          }
          this.findTablePoint()
        }
      })
    },
    resetPage() {
      this.formInline = {
        hfmCode: [],
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        client: '',
        laborCode: '',
        department: ''
      }
      this.searchStatus = 'ALL'
      this.pageConfig.pageSize = 20
      this.pageConfig.pageNum = 1
      this.formInline.orderColumn = ''
      this.formInline.orderAsc = ''
      this.$refs.tTableRef.clearSort()
    },
    rowClick() {},
    search() {
      this.pageConfig.pageNum = 1;
      this.formInline.orderColumn = '';
      this.formInline.orderAsc = '';
      switch(this.searchStatus){
        case 'ALL':
          this.columnConfig = TableColumnsObj.ALL
          break;
        case 'To Be Approved':
          this.columnConfig = TableColumnsObj.ToBeApproved
          break;
        case 'APPROVED':
          this.columnConfig = TableColumnsObj.APPROVED
          break;
        case 'REJECTED':
          this.columnConfig = TableColumnsObj.REJECTED
          break;
        default:
          this.columnConfig = TableColumnsObj.ALL
          break;
      }
      this.queryApi()
    },
    next(id){
      let index = 0
      for (let i = 0; i < this.tableDataNext.length; i++) {
        if(this.tableDataNext[i].id === id){
          index = i
        }
      }
      if(index === this.tableDataNext.length -1 ){
        const data = {
          ...this.formInline,
          searchType: this.searchType,
          searchStatus: this.searchStatus,
          pageSize: this.pageConfig.pageSize,
          pageNum: this.pageNum += 1
        }
        financeList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            if (res.data && res.data.list && res.data.list.length > 0) {
              this.tableDataNext = res.data.list
              this.financialQuotaParam.id = this.tableDataNext[0].id
              this.financialQuotaParam.hcId = this.tableDataNext[0].number
              this.$refs.financialQuotaDrawerRef.reloadDate()
            } else {
              this.$message.error('没有更多数据了')
            }
          }
        })
      }else {
        console.log(this.tableDataNext[index + 1].id);
        this.financialQuotaParam.id = this.tableDataNext[index + 1].id
        this.financialQuotaParam.hcId = this.tableDataNext[index + 1].number
        this.$refs.financialQuotaDrawerRef.reloadDate()
      }

    },
    selectAll() {},
    sortChange(column){
      console.log(column.prop,column.order,'777')
      if(column.order === 'descending' ){
        this.formInline.orderColumn  = column.prop  //  当前排序的字段
        this.formInline.orderAsc = false // 排序或倒序
      }else if(column.order === 'ascending'){
        this.formInline.orderColumn = column.prop
        this.formInline.orderAsc = true
      }else{
        this.formInline.orderColumn = ''
        this.formInline.orderAsc = ''
      }
      this.queryApi()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.HC {
  ::v-deep .el-table td.el-table__cell > .cell{
    word-break: normal;
    line-height: 16px;
  }
  ::v-deep .column_selection{
    margin-left: 2px;
  }
  .textHover {
    font-size: 12px !important;
    font-family: ArialMT;
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
  .messageImg{
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .total_box{
    line-height: 30px;
    display: flex;
    flex-direction: row;
    .total_text{
      font-size: 18px;
      span{
        font-size:14px;
      }
    }
    .total_text:first-child{
      margin-right: 40px;
    }
  }
}
.jobTitle_box{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
</style>
