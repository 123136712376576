<template>
  <div class="financialQuota">
    <el-drawer
      :visible.sync="drawer"
      size="95%"
      direction="btt"
      @close="drawerClose"
      destroy-on-close
    >
      <template slot="title">
        <slot name="title">
          <div class="financialQuota__title">
            <div class="titleTop">
              {{ partTitle }} / <span>{{ param.title }}</span>
              <span class="app_total_text" v-if="param.searchStatus === 'To Be Approved' && param.position === 'hc'">
                （待审批：{{total}}条）
              </span>
              <!--              <div class="line"></div>-->
            </div>
            <div v-if="param.position === 'hc'">
              <el-button v-show="formType == 'cto' ? !(param.ctoApproveStatus === 1 || param.groupCFOApprovalStatus === 1) : !(param.groupCFOApprovalStatus === 1)" role="pass" v-permission type="primary" @click="pass()" :loading="loading">通过</el-button>
              <el-button v-show="formType == 'cto' ? !(param.ctoApproveStatus === 1 || param.groupCFOApprovalStatus === 1) : !(param.groupCFOApprovalStatus === 1)" role="rejected" v-permission @click="showReject()" :loading="loading"
              >驳回</el-button >
              <el-button v-show="formType == 'cto' ? !(param.ctoApproveStatus === 1 || param.groupCFOApprovalStatus === 1) : (param.groupCFOApprovalStatus != 1 && param.groupCFOApprovalStatus != 2)" role="message" v-permission :loading="loading" @click="leaveMessage()"
              >留言</el-button>
            </div>
            <div v-if="param.position === 'hc'">
              <el-button @click="next">下一条</el-button>
            </div>
            <!--            <div class="buttonLine">-->
            <!--              <el-button @click="goback">返回</el-button>-->
            <!--            </div>-->
          </div>
        </slot>
      </template>
      <div class="financialQuota__body">
        <div class="financialQuota__tab" v-if="param.position !== 'hc'">
          <el-tabs v-model="tabCon">
            <el-tab-pane label="HC审批" name="HCInformation"></el-tab-pane>
            <el-tab-pane label="Offer审批" name="OfferProposal"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="financialQuota__content" id="financialQuota__content">
          <HCInformation
            ref="HCInformationRef"
            :params="param"
            v-if="tabCon === 'HCInformation'"
          />
          <OfferProposal
            :id="param.id + ''"
            ref="financialQuotaRef"
            v-if="tabCon === 'OfferProposal'"
          ></OfferProposal>
          <!-- param.searchStatus !== 'To Be Approved' -->
          <financeRecord v-if="( param.position === 'hc') || rejected" ref="financeRecordRef" :hcId ='param.hcId'/>
        </div>
      </div>
    </el-drawer>
    <LeaveMessageDrawer ref="leaveMessageDrawerRef" @queryList="queryList" :id="param.id + ''" />
  </div>
</template>

<script>
import HCInformation from '@/views/quotaManagement/components/HCInformation'
import OfferProposal from '@/views/quotaManagement/components/OfferProposal'
import financeRecord from "@/views/financialApproval/financeRecord";
import LeaveMessageDrawer from "@/views/financialApproval/leaveMessageDrawer.vue";
export default {
  name: 'finaciaQuota',
  inject: ['formType'],
  props:{
    param:{
      type:Object,
      default:{
        id:'',
        tabCon: 'HCInformation',
        position: 'hc',
        title: '财务审批',
        target: 'financial',
        searchStatus:'ALL',
      }
    },
  },
  watch:{
    'param.id': {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true
    },
  },
  components: {
    LeaveMessageDrawer,
    HCInformation,
    OfferProposal,
    financeRecord
  },
  created() {
    console.log(this.param);
    this.position = this.param.position
    this.title = this.param.title
    this.status = this.param.status
    let partTitle = this.param.target
    if (partTitle === 'financial') {
      this.partTitle = '财务审批'
    }
  },
  computed: {
    statusDisable() {
      return this.status === 'chat'
    },
  },
  data() {
    return {
      tabCon: 'HCInformation',
      position: 'hc',
      title: 'HC 信息',
      partTitle: '财务审批',
      drawer: false,
      detailId: '',
      total: 0,
      rejected: false,
      loading: false,
    }
  },
  methods: {
    leaveMessage(){
      this.$refs.leaveMessageDrawerRef.show(this.param.id)
    },
    queryList(){
      this.$emit('queryList')
    },
    pass(){
      this.loading = true
      this.$emit('pass',this.param.id)
    },
    showReject(){
      this.detailId=this.param.id
      this.$emit('showReject','')
    },
    next(){
      this.loadingData()
      if(this.param.searchStatus === 'To Be Approved'){
        this.rejected = false
      }
      console.log(this.param.id);
      this.$emit('next',this.param.id)
    },
    loadingData(){
      let loading = this.$loading({ fullscreen: true })
      setTimeout(()=>{
        loading.close()
      },1000)
    },
    reloadDate(){
      if(this.$refs.HCInformationRef){
        this.$refs.HCInformationRef.getData()
      }
      if(this.$refs.financeRecordRef){
        this.$refs.financeRecordRef.getFinanceRecord(this.param.hcId)
      }
    },
    drawerClose(){
      this.detailId=''
    },
  }
}
</script>

<style lang="scss" scoped>
.financialQuota {
  //height: calc(100vh - 60px - 40px);
  background: white;
  overflow: hidden;
  padding: 20px;
  position: relative;
  ::v-deep .el-drawer__header{
    margin-bottom: 20px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    padding: 0 240px 0 80px;
    .titleTop {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
      span {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .line {
    height: 1px;
    width: 100%;
    margin: 0;
    background: rgb(224, 227, 234);
    position: absolute;
    left: 0;
    top: 55px;
  }
  .buttonLine {
    position: absolute;
    top: 12px;
    right: 10px;
  }
  &__body{
    margin: 0 100px;
  }
  &__tab {
    margin-bottom: 10px;
  }
  &__content {
    //padding-bottom: 30px;
    //height: calc(100vh - 60px - 40px - 50px - 70px);
    //overflow-y: scroll;
    //overflow-x: hidden;
  }
  .app_total_text{
    font-size: 12px !important;
  }
}
</style>
