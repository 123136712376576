<template>
  <el-drawer
    title="审批留言"
    :visible.sync="drawer"
    :direction="direction"
    :size="800"
    :before-close="handleClose">
    <div class="top_box">
      <el-input
        type="textarea"
        :rows="10"
        show-word-limit
        maxlength="1000"
        placeholder="请输入留言"
        v-model="content">
      </el-input>
      <div class="btn_box">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading">提 交</el-button>
      </div>
    </div>
    <div class="footer_box">
      <el-steps direction="vertical">
        <el-step v-for="item in stepsData" status="process">
          <template slot="icon" >
            <i class="step" slot="icon"></i>
          </template>
          <template slot="title" >
            <div class="flex">
              <span class="time_box" style="margin-right: 8px">{{item.createUserName}} </span>
              <span class="time_box">{{stampToTime(item.createTime)}} </span>
            </div>
          </template>
          <template slot="description" >
            <div class="description_box">
              <span style="max-width: 800px">
              {{item.content}}
            </span>
            </div>
          </template>
        </el-step>
      </el-steps>
    </div>
  </el-drawer>
</template>
<script>
import { getMessageList,saveMessage } from "@/api/finance";
import { stampToTime } from "@/utils/util";
export default {
  name: "leaveMessageDrawer",
  props:{
    id:''
  },
  data(){
    return{
      drawer: false,
      direction: 'rtl',
      content: '',
      loading: false,
      stepsData: [],
    }
  },
  methods:{
    stampToTime,
    show(id){
      this.drawer = true
      this.id = id
      getMessageList({id:id})
        .then(res=>{
          if(res.code === 200){
            this.stepsData = res.data || []
          }
        })
    },
    handleClose(){
      this.content = ''
      this.loading = false
      this.drawer = false
    },
    submit(){
      this.loading = true
      saveMessage({
        "content": this.content,
        "id": this.id
      }).then(res=>{
        this.loading = false
          if(res.code === 200){
            this.$message.success(res.msg)
            this.$emit('queryList')
            this.handleClose()
          }
        })
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-drawer__header{
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 29px;
}
.top_box{
  padding: 0 16px;
  .btn_box{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
}
.footer_box{
  margin-top: 35px;
  border-top: 1px solid #EBEEF5;
  padding: 18px 16px 0 16px;
  .time_box{
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #828282;
    line-height: 14px;
  }
  .description_box{
    background: #F4F4F4;
    border-radius: 4px;
    padding: 18px 12px;
  }
}
::v-deep .el-step__description{
  padding-right: 0;
}
::v-deep .el-step.is-vertical .el-step__head{
  width: 5px;
}
::v-deep .el-step.is-vertical .el-step__title{
  padding-top: 4px;
}
::v-deep .el-step__head{
  width: 5px;
  .el-step__title{
    line-height: 0;
  }
  .el-step__line{
    width: 1px;
    top: 10px;
    bottom: -10px;
    left: 2px;
    display: block;
  }
  .el-step__icon.is-text{
    border: none;
  }
  .el-step__icon{
    background-color: #BA9765;
    width: 5px;
    height: 5px;
  }
}
</style>
